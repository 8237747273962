<app-overlay (click)="close()"></app-overlay>
<div class="modal">
  <button class="close-btn" (click)="close()"></button>
  <div class="title">
    {{ cycle.name }} Cycle - {{ note.createdAt | date : "EEEE, MMMM" }}
    {{ note.createdAt | date : "d" | dayEnding }},
    {{ note.createdAt | date : "h:mm a" }}
  </div>
  <app-separator [background]="'rgba(255, 255, 255, 0.10)'"></app-separator>
  <div class="note-content" [formGroup]="form">
    <textarea formControlName="text">{{ text }}</textarea>
  </div>
  <app-separator [background]="'rgba(255, 255, 255, 0.10)'"></app-separator>
  <div class="error-block">
    <div class="error" *ngIf="form.get('text').invalid">
      Max length 2000 characters
    </div>
  </div>
  <div class="buttons">
    <button
      class="btn edit-btn"
      (click)="edit()"
      [disabled]="!form.dirty || form.invalid"
    >
      Edit</button
    ><button class="btn delete-btn" (click)="delete()">Delete</button>
  </div>
</div>

<ng-template #confirmCloseModal>
  <div class="title">
    {{
      modalContent === "close"
        ? "Entered data will be lost. Are you sure you want to close?"
        : "Note will be deleted"
    }}
  </div>
  <button (click)="confirmClose()" class="action">Proceed</button>
</ng-template>
