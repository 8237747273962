<app-overlay (click)="close()"></app-overlay>
<div class="modal ion-page" *ngIf="data">
  <div class="inner-content">
    <button class="close-btn" (click)="close()"></button>
    <div class="wrap">
      <div class="title">Switch membership billing cycle</div>
      <app-separator [background]="'rgba(255, 255, 255, 0.2)'"></app-separator>
      <div class="title small">
        Payable on {{ data.nextBilling.billingDate | date : "LLLL dd, yyyy" }}
      </div>
      <div class="sub-block">
        <div class="sub-title">Essential plan (monthly)</div>
        <div class="price">${{ monthlyPrice }}</div>
      </div>

      <div class="info-block">
        <div class="info-icon">
          <img
            src="../../../../../assets/modals/upgrade/info-icon.svg"
            alt="Info Icon"
          />
        </div>
        <div class="content">
          <div class="title">
            Your subscription will change on the next billing cycle
          </div>
          <div class="text">
            We’ll switch you on to your new plan after your current are finishes
          </div>
        </div>
      </div>

      <div class="agreement">
        By clicking button below you are agree to our
        <a (click)="privacyPolicyModal = true" class="privacy-link"
          >Terms of Service and Privacy Policy</a
        >
      </div>

      <button class="confirm-btn" (click)="confirmSwitch()">switch</button>
    </div>
  </div>
</div>
<app-privacy-policy
  *ngIf="privacyPolicyModal"
  (closePolicy)="privacyPolicyModal = false"
></app-privacy-policy>
