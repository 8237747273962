export enum SubscriptionPlan {
  'none' = 0,
  'trialist' = 1,
  'basic' = 2,
  'elite' = 3,
  'plus' = 4,
  'lifetime' = 5,
  'staff' = 6,
  'admin' = 7,
  'specialCase' = 8,
}

export enum Plan {
  none = 'None',
  trialist = 'Trialist',
  basic = 'Basic',
  elite = 'Elite',
  plus = 'Plus',
  lifetime = 'Lifetime',
  staff = 'Staff',
  admin = 'Admin',
  specialCase = 'SpecialCase',
}
