import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UpgradePlanModel } from 'src/app/core/models/plans/upgrade-plan.model';

@Component({
  selector: 'app-mob-upgrade-subscription',
  templateUrl: './mob-upgrade-subscription.component.html',
  styleUrls: ['./mob-upgrade-subscription.component.scss'],
})
export class MobUpgradeSubscriptionComponent {
  @Input() data: UpgradePlanModel;
  @Input() planFeatures: string[];
  @Output() onUpgrade = new EventEmitter();
  @Output() onTermsShow = new EventEmitter();
  @Output() onClose = new EventEmitter();

  public upgrade() {
    this.onUpgrade.emit();
  }

  public showTerms() {
    this.onTermsShow.emit();
  }

  public close() {
    this.onClose.emit();
  }
}
