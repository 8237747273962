<ion-app>
  <app-burger-menu *ngIf="isMobile && !hiddenBurgerMenu"></app-burger-menu>
  <ion-router-outlet></ion-router-outlet>
</ion-app>

<app-modal></app-modal>

<ng-template #confirmOnboarding>
  <img src="assets/icon/favicon.png" alt="Complete onboarding image" />
  <div class="title">ONBOARDING SUCCESS</div>
  <div class="description">
    You’ve finished onboarding.<br />You will be redirected to purchasing a
    membership if you do not already have one
  </div>
  <button (click)="proceedModal()" class="action">Proceed</button>
</ng-template>
