import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SubscriptionInterval } from 'src/app/core/enums/subscription-interval';
import { UpgradePlanParamsStripe } from 'src/app/core/models/plans/upgrade-plan-params-stripe';
import { UpgradePlanModel } from 'src/app/core/models/plans/upgrade-plan.model';

@Component({
  selector: 'app-switch-subscription',
  templateUrl: './switch-subscription.component.html',
  styleUrls: ['./switch-subscription.component.scss'],
})
export class SwitchSubscriptionComponent implements OnInit {
  public privacyPolicyModal: boolean;
  public monthlyPrice: number;
  @Input() data: UpgradePlanModel;

  constructor(private modalController: ModalController) {}

  ngOnInit(): void {
    this.monthlyPrice =
      this.data.nextBilling.price /
      (Number(SubscriptionInterval[this.data.newPlan.subscriptionInterval]) *
        this.data.newPlan.subscriptionIntervalCount);
  }

  public close(): void {
    this.modalController.dismiss();
  }

  public confirmSwitch() {
    const params: UpgradePlanParamsStripe = {
      plan: this.data.newPlan.plan,
      subscriptionInterval: this.data.newPlan.subscriptionInterval,
      subscriptionIntervalCount: this.data.newPlan.subscriptionIntervalCount,
    };
    this.modalController.dismiss(params);
  }
}
