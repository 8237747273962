import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, tap } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationInterceptor implements HttpInterceptor {
  response = '';

  constructor(
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
      const token: string = this.authService.getToken();
      if (token) {
        request = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + token),
        });
      }
    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) this.response = 'Server response';
        },
        async (err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 403) {
              await this.authService.refreshToken();
            }
          }
        }
      )
    );
  }
}
