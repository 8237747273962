import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';

import { Subscription } from 'rxjs';

import { AppMenuItem } from './menu-item/menu-item.component';
import { AppQuery } from 'src/app/core/state/app/app.query';
import { UserModel } from 'src/app/core/models/user/user';
import { AppSwitchingModalComponent } from '../modals/app-switching-modal/switching-modal.component';
import { ScreenService } from 'src/app/core/services/screen.service';
import { DashboardConstants } from '../../constants/dashboard.constants';
import { ModalService } from '../modals/modal/modal.service';
import { Plan } from 'src/app/core/models/plans/plans-subscription-types';
import { AppStore } from 'src/app/core/state/app/app.store';

@Component({
  selector: 'app-sidemenu-component',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state(
        'closed',
        style({
          width: '70px',
        })
      ),
      state(
        'open',
        style({
          width: '250px',
        })
      ),
      transition('closed => open', [animate('0.1s')]),
      transition('open => closed', [animate('0.1s')]),
    ]),

    trigger('openCloseAppSwitcher', [
      state(
        'closed',
        style({
          width: '28px',
          right: '20px',
        })
      ),
      state(
        'open',
        style({
          width: '210px',
          right: '20px',
        })
      ),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
})
export class MenuComponent implements OnInit, OnDestroy {
  activeRouteId: string;
  protected subscription = new Subscription();
  public isMobile = false;
  public isMuted = false;
  public isDesktop = true;
  public collapsed: boolean;
  public trialAvailable = true;
  public mentor = false;
  public isCurrentUserHasSubscription: boolean;
  expandedSwitcher = false;
  isOpenSwithcerModal = false;
  user: UserModel;

  appPages: AppMenuItem[] = [
    // {
    //   id: 'home',
    //   title: 'Home',
    //   url: '/home',
    //   icon: 'home',
    //   notifications: 0,
    //   visible: true,
    //   show: true,
    // },
    {
      id: 'dashboard',
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'dashboard',
      notifications: 0,
      visible: true,
      show: true,
    },
    // {
    //   id: 'trade',
    //   title: 'Trading Apps',
    //   url: '/trade',
    //   icon: 'trade',
    //   notifications: 0,
    //   visible: true,
    //   show: true,
    // },
    // {
    //   id: 'copytrading',
    //   title: 'Copy Trading',
    //   url: '/copytrading',
    //   icon: 'copytrading',
    //   notifications: 0,
    //   visible: true,
    //   show: true,
    // },
    // {
    //   id: 'membership',
    //   title: 'Membership',
    //   url: '/membership',
    //   icon: 'membership',
    //   notifications: 0,
    //   visible: true,
    //   show: true,
    // },
    // {
    //   id: 'support',
    //   title: 'Support',
    //   url: 'dashboard/support',
    //   icon: 'support',
    //   notifications: 0,
    //   show: true,
    //   visible: true,
    //   isMobile: true,
    // },
    {
      id: 'adminPanel',
      title: 'Admin Panel',
      url: 'dashboard/admin-panel',
      icon: 'admin-panel',
      notifications: 0,
      visible: false,
      show: false,
    },
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    public menuCtrl: MenuController,
    private appQuery: AppQuery,
    private modalCtrl: ModalController,
    private screeService: ScreenService,
    private modalService: ModalService,
    private appStore: AppStore
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.appQuery
        .select((x) => x.currentUser)
        .subscribe((user) => {
          if (user) {
            this.user = user;
            this.isCurrentUserHasSubscription =
              this.user.data.access !== Plan.none;
          }
        })
    );
    this.subscription.add(
      this.appQuery
        .select((x) => x.sideMenuCollapsed)
        .subscribe((data: boolean) => {
          this.collapsed = data;
        })
    );
    const adminPanel = this.appPages.find((el) => {
      return el.id === 'adminPanel';
    });
    this.subscription.add(
      this.appQuery
        .select((x) => x.isAdminMode)
        .subscribe((adminMode) => {
          adminPanel.show = adminMode;
          adminPanel.visible = adminMode;
          this.cdr.markForCheck();
        })
    );
    this.collapsed = this.appStore.getValue().sideMenuCollapsed;
    this.isMobile = this.screeService.isMobileView;
  }

  ionViewWillEnter() {
    this.menuCtrl.enable(true);
  }

  async openStartTrial() {}

  public navigate(url): void {
    sessionStorage.setItem(DashboardConstants.isHomeButtonClicked, 'clicked');
    this.modalService.close();
    this.router.navigate([url]);
  }

  public toggle(): void {
    this.appStore.update({ sideMenuCollapsed: !this.collapsed });
    this.isMobile = this.screeService.isMobileView;
  }

  public async closeMenu(): Promise<void> {
    await this.menuCtrl.close('main-menu-id');
  }

  public toggleSounds(): void {
    // this.userConfigService.toggleMuted();
  }

  async inviteFriend(): Promise<void> {
    // const addReviewModal = await this.modalCtrl.create({
    //   component: InviteModalComponent,
    //   backdropDismiss: true,
    //   cssClass: ['modal-medium '],
    // });
    // await addReviewModal.present();
  }

  public goToDiscrod(): void {
    window.open(
      `https://discord.com/channels/542225548982747147/990358149703815178`,
      '_blank'
    );
  }

  public expandAppSwitcher(value: boolean) {
    this.expandedSwitcher = value;
  }

  public async openSwitchingModal() {
    const appSwitcherModal = await this.modalCtrl.create({
      component: AppSwitchingModalComponent,
    });
    await appSwitcherModal.present();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

export class MenuItem {
  id?: string;
  title?: string;
  url?: string;
  icon?: string;
  notifications?: number;
  show?: boolean;
  children?: MenuItem[];
}

export class MenuFolder {
  id?: string;
  title?: string;
  url?: string;
  icon?: string;
  notifications?: number;
  show?: boolean;
  expanded?: boolean;
  children?: MenuItem[];
}
