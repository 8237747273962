import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { SharedDataService } from 'src/app/core/services/shareddata.service';

@Directive({
  selector: '[appHover]',
})
export class HoverDirective {
  @Input() alert: any;
  @Input() ticker: any;
  public spotlight;
  @Input('hoverSpotlight') set hoverSpotlight(value) {
    this.spotlight = value;
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private sharedService: SharedDataService
  ) {}

  @HostListener('mouseenter') onMouseOver(event: any) {
    if (this.alert || this.ticker) {
      const rect = this.el.nativeElement.getBoundingClientRect();
      this.renderer.setStyle(
        this.el.nativeElement.querySelector('.wrap'),
        'backgroundColor',
        '#000000'
      );
      let popupHeight;
      let popupWidth;
      if (this.alert) {
        this.sharedService.hoveredAlert.next(this.alert);
        const popup = document.getElementById('alert-popup');
        popupHeight = 239;
        popupWidth = 275;
        this.renderer.setStyle(
          popup,
          'left',
          `${(event || window.event).pageX - popupWidth / 4}px`
        );
        this.renderer.setStyle(popup, 'top', `${rect.top - popupHeight - 10}px`);
      }
      if (this.ticker) {
        this.sharedService.hoveredTicker.next(this.ticker);
        popupHeight = 341;
        popupWidth = 315;
        const popup = document.getElementById('ticker-popup');

        this.renderer.setStyle(
          popup,
          'left',
          `${(event || window.event).pageX - popupWidth / 4}px`
        );
        this.renderer.setStyle(popup, 'top', `${rect.top - popupHeight - 10}px`);
      }
    }
  }

  @HostListener('mouseleave') onMouseOut() {
    if (this.alert) {
      this.sharedService.hoveredAlert.next(null);
      this.renderer.setStyle(
        this.el.nativeElement.querySelector('.wrap'),
        'backgroundColor',
        '#14171a'
      );
    }
    if (this.ticker) {
      this.sharedService.hoveredTicker.next(null);
      this.renderer.setStyle(
        this.el.nativeElement.querySelector('.wrap'),
        'backgroundColor',
        '#14171a'
      );
    }
    if (this.spotlight) {
      if (this.el.nativeElement.id === 'spotlight-popup') {
        this.sharedService.hoveredSpotlight.next(null);
      }
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    this.sharedService.hoveredSpotlight.next(null);
    const rect = this.el.nativeElement.getBoundingClientRect();
    if (this.spotlight) {
      const popupWidth = 349;
      this.sharedService.hoveredSpotlight.next(this.spotlight);
      const popup = document.getElementById('spotlight-popup');
      if (this.el.nativeElement.id == 'spotlight-item') {
        this.renderer.setStyle(
          popup,
          'left',
          `${rect.left - (popupWidth - rect.width) / 2}px`
        );
        this.renderer.setStyle(popup, 'top', `${rect.top + rect.height}px`);
      }
    }
  }
}
