<app-overlay (click)="close()"></app-overlay>
<div class="switching-modal">
  <div class="icon"></div>
  <div class="title">Switch Server</div>
  <div class="buttons">
    <div
      class="item xt"
      [style.background-image]="'url(assets/modals/xt-switch.svg)'"
      (click)="navigateToXT()"
    ></div>
    <div
      class="item ct"
      [style.background-image]="'url(assets/modals/ct-switch.svg)'"
    ></div>
  </div>
</div>
