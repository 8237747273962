import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public privacyPolicyModal: boolean = false;

  @Input() perks = false;

  constructor() {}

  public showPrivacyPolicy(): void {
    this.privacyPolicyModal = true;
  }

  public closeModal(): void {
    this.privacyPolicyModal = false;
  }
}
