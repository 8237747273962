<div class="modal ion-page">
  <div class="inner-content">
    <button class="close-btn" (click)="close()"></button>
    <div class="top-block">
      <div class="title">Upgrade membership plan</div>
    </div>
    <div class="wrap">
      <div class="features">
        <div class="plans-wrap">
          <div class="plan-wrap">
            <div
              class="icon"
              [ngStyle]="{
                'background-image':
                  'url(\'/assets/icon/' +
                  data.currentPlan.plan.toLowerCase() +
                  '-icon.svg\')',
                'background-size': 'contain'
              }"
            ></div>
            <div class="content">
              <div class="plan-current">
                <div class="title">
                  {{
                    data.currentPlan.plan === "Plus"
                      ? "Elite Plus"
                      : data.currentPlan.plan
                  }}
                </div>
                <div class="title-text">
                  Days left
                  <span class="days-count"
                    >{{ data.currentPlan.daysLeft }} days</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="plan-wrap">
            <div
              class="icon"
              [ngStyle]="{
                'background-image':
                  'url(\'/assets/icon/' +
                  data.newPlan.plan.toLowerCase() +
                  '-icon.svg\')',
                'background-size': 'contain'
              }"
            ></div>
            <div class="content">
              <div class="plan-upgrade">
                <div class="title">
                  {{
                    data.newPlan.plan === "Plus"
                      ? "Elite Plus"
                      : data.newPlan.plan
                  }}
                </div>
                <div class="title-text">
                  Days convert
                  <span class="days-count"
                    >{{ data.newPlan.daysLeft }} days</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <ion-content class="features-list" appCustomScrollbar>
          <ul>
            <li class="feature" *ngFor="let item of planFeatures">
              {{ item }}
            </li>
          </ul></ion-content
        >
      </div>

      <div class="payment">
        <div class="top">
          <div class="plans">
            <div class="plan first">
              <div class="title">Payable today</div>
              <div class="sum">$0.00</div>
              <div class="sub-text">
                Your {{ data.currentPlan.daysLeft }} days of
                {{ data.currentPlan.plan }} plan converts to
                {{ data.newPlan.daysLeft }} days of {{ data.newPlan.plan }} plan
              </div>
            </div>
            <div class="plan last">
              <div class="title">
                Payable on
                {{ data.nextBilling.billingDate | date : "LLLL dd, yy" }}
              </div>
              <div class="sum">$ {{ data.nextBilling.price }}</div>
              <div class="sub-text">
                {{ data.newPlan.plan }} plan, Tax included
              </div>
            </div>
          </div>

          <div class="info-block">
            <div class="info-icon">
              <img
                src="../../../../../assets/modals/upgrade/info-icon.svg"
                alt="Info Icon"
              />
            </div>
            <div class="text">
              <div class="title">You won't be billed today</div>
              <div class="text">
                We'll just update the number of days on your new subscription.
              </div>
            </div>
          </div>
        </div>

        <div class="bottom">
          <div class="agreement">
            By clicking button below you are agree to our
            <a (click)="showTerms()" class="privacy-link"
              >Terms of Service and Privacy Policy</a
            >
          </div>
          <button class="upgrade-btn" (click)="upgrade()">UPGRADE</button>
        </div>
      </div>
    </div>
  </div>
</div>
