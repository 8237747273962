<app-overlay (click)="close()"></app-overlay>
<div class="modal">
  <button class="close-btn" (click)="close()"></button>
  <div class="title">About</div>
  <div class="info-wrap" *ngIf="user && user.data && user.data.stats">
    <div class="row">
      <div class="column-title">Total Alerts</div>
      <div class="column">{{ user.data.stats.alertsTotalCount }}</div>
    </div>
    <div class="row">
      <div class="column-title">Followers</div>
      <div class="column">{{ user.data.stats.followersCount }}</div>
    </div>
    <div class="row">
      <div class="column-title">Following</div>
      <div class="column">{{ user.data.stats.followingsCount }}</div>
    </div>
    <div class="row">
      <div class="column-title">Date Joined</div>
      <div class="column">{{ user.data.dateJoined | date : "MM/dd/yyyy" }}</div>
    </div>
  </div>
  <div class="trading-types">
    <div class="type" *ngFor="let item of tradingTypes">{{ item }}</div>
  </div>
  <app-separator></app-separator>
  <div class="title">Badges</div>
  <div class="badges">
    <div
      class="badge"
      *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
      [style.background-image]="
        'url(assets/dashboard/profile/badge-' + item + '.svg)'
      "
    ></div>
  </div>
  <button class="close-btn-bottom" (click)="close()">Close</button>
</div>
