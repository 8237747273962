import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Plan } from 'src/app/core/models/plans/plans-subscription-types';
import { UserData } from 'src/app/core/models/user/user';
import { plansConstants } from 'src/app/pages/plans/constants/plans.constants';

@Component({
  selector: 'app-profile-about-modal-mobile',
  templateUrl: './profile-about-modal-mobile.component.html',
  styleUrls: ['./profile-about-modal-mobile.component.scss'],
})
export class ProfileAboutModalMobileComponent {
  @Input() user: UserData;
  @Input() instagramLink: string;
  @Input() twitterLink: string;
  public access = Plan;
  public elitePlanTitle = plansConstants.elitePlusTitle;

  constructor(
    private modalController: ModalController,
  ) {}

  public close(): void {
    this.modalController.dismiss();
  }

  public edit(): void {
    this.modalController.dismiss({
      instagramLink: this.user.profile.instagramLink,
      twitterLink: this.user.profile.twitterLink,
      websiteLink: this.user.profile.websiteLink,
      about: this.user.profile.about,
    });
  }
}
