import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-cancel-subscription-modal',
  templateUrl: './cancel-subscription-modal.component.html',
  styleUrls: ['./cancel-subscription-modal.component.scss'],
})
export class CancelSubscriptionModalComponent implements OnInit{
  @Input() savings: string;
  public benefits = [
    {
      text: 'A larger sized token airdrop if we do deploy one',
      button: '84 PTS',
    },
    { text: 'Decreased monthly membership costs', smallText: '' },
    {
      text: 'Stacked Raffle tickets that help you win weekly cash and xbits giveaways',
    },
    { text: 'A badge of honor on your profile' },
    { text: 'Priority access to the Xtrades Seed Investment Round' },
  ];

  constructor(private modalController: ModalController) {}

  ngOnInit(): void {
    this.benefits[1].smallText = `Saved $${this.savings}`;
  }

  public close(data = false): void {
    this.modalController.dismiss(data);
  }
}
