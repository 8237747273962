import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-switching-modal',
  templateUrl: './switching-modal.component.html',
  styleUrls: ['./switching-modal.component.scss'],
})
export class AppSwitchingModalComponent {
  constructor(private modalCtrl: ModalController) {}

  async close() {
    await this.modalCtrl.dismiss();
  }

  navigateToXT() {
    window.location.href = environment.xtradesApp;
  }
}
