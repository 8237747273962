import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  OAuth2AuthenticateOptions,
  OAuth2Client,
} from '@byteowls/capacitor-oauth2';
import { lastValueFrom } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

import { environment } from 'src/environments/environment';
import { HttpClientService } from './http-client.service';
import { UserService } from './user.service';
import {
  clearCookie,
  getCookie,
  setCookie,
  validationError,
} from '../../shared/utils/helpers';
import { ToastService } from './toast.service';
import { SpinnerService } from './spinner.service';
import { UserModel } from '../models/user/user';
import { AppStore } from '../state/app/app.store';
import { DASHBOARD_URL } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  discordOptions: OAuth2AuthenticateOptions = {
    appId: environment.discordClientId,
    scope: 'identify+email+guilds.join',
    authorizationBaseUrl: 'https://discord.com/api/oauth2/authorize',
    accessTokenEndpoint: '',
    logsEnabled: !environment.production,
    web: {
      responseType: 'token',
      redirectUrl: window.location.origin + '/signin-discord',
      windowTarget: '_self',
    },
  };
  public user: UserModel;
  public static readonly AccessTokenCookieName: string = 'accessToken';
  public static readonly RefreshTokenCookieName: string = 'refreshToken';

  constructor(
    private http: HttpClientService,
    private userService: UserService,
    private toast: ToastService,
    private spinner: SpinnerService,
    private router: Router,
    private appStore: AppStore
  ) {}

  get isAuthenticated(): boolean {
    return !!this.getToken();
  }
  
  public async initUser() {
    if (this.getToken()) {
      if (!this.user) {
        try {
          await this.userService.getCurrentUser();
        } catch (error) {
          console.error(error.message);
        }
      }
    }
  }

  public getToken() {
    return getCookie(AuthService.AccessTokenCookieName);
  }

  public getRefreshToken() {
    return getCookie(AuthService.RefreshTokenCookieName);
  }

  public async signInDiscord(redirectUrl?: string): Promise<void> {
    try {
      await this.getDiscordToken(redirectUrl);
    } catch (err) {
      this.handleOauthError(err);
      throw err;
    }
  }

  private handleOauthError(err) {
    const error = err.allValidationErrors?.join('\n') ?? err.message ?? err ?? 'Server error';
    if (['USER_CANCELLED'].includes(error)) {
      return;
    }
    this.toast.presentToast(`Error: ${error}`, 'error');
  }

  private async getDiscordToken(redirectUrl?: string): Promise<string> {
    if (!redirectUrl) {
      redirectUrl = DASHBOARD_URL;
    }

    this.discordOptions.web.state = encodeURI(redirectUrl);

    const response = await OAuth2Client.authenticate(this.discordOptions);

    return (
      response.access_token_response?.access_token || response.access_token
    );
  }

  async getAccessTokenFromAPI(discordToken: string): Promise<void> {
    const url = `Auth/discord`;
    const body = {
      token: discordToken,
      referrerUserName: '',
      referralSource: 0,
    };

    try {
      const accessToken$ = this.http.post(url, body);
      const data = await lastValueFrom(accessToken$);
      if (data && data.data && data.data.accessToken) {
        try {
          const role: string[] = jwtDecode(data.data.accessToken)[
            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
          ];
          const isAdmin = role?.includes('admin') ?? false;

          if (isAdmin) {
            this.appStore.update({ isAdmin: true });
          }
          setCookie(
            AuthService.AccessTokenCookieName,
            data.data.accessToken,
            data.data.expiresIn,
            data.data.refreshToken
          );
          localStorage.removeItem('auth-error');
          await this.userService.getCurrentUser();
          return;
        } catch (error) {
          console.log(error.message);
        }
      } else {
        throw new Error('Access token not found in API response.');
      }
    } catch (error) {
      const message = validationError(error);
      await this.spinner.hide();
      localStorage.setItem('auth-error', 'error');
      await this.toast.presentToast(message, 'error');
      await this.router.navigate(['/home']);
      localStorage.removeItem('auth-error');
    }
  }

  public logout(): void {
    clearCookie(AuthService.AccessTokenCookieName);
    this.appStore.update({ currentUser: null });
  }

  async refreshToken(): Promise<void> {
    if (this.getRefreshToken()) {
      document.cookie =
        AuthService.AccessTokenCookieName +
        '=' +
        this.getRefreshToken() +
        '; path=/';
    }
    const url = `Auth/refresh`;
    try {
      const data: any = await lastValueFrom(this.http.post(url, null));
      if (data.data.accessToken) {
        setCookie(
          AuthService.AccessTokenCookieName,
          data.data.accessToken,
          data.data.expiresIn,
          data.data.refreshToken
        );
      }
    } catch (error) {
      clearCookie(AuthService.AccessTokenCookieName);
      this.appStore.update({ currentUser: null });
      this.router.navigate(['/']);
    }
  }

  async connectPayment(checkoutSessionId): Promise<void> {
    await this.http
      .post(`Payments/connect/${checkoutSessionId}`, null)
      .toPromise();
  }
}
