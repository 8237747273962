<ion-footer class="footer" [ngStyle]="{ 'padding-bottom.px': perks ? 20 : 0 }">
  <div class="footer__block">
    <div class="footer__block-links">
      <div class="footer__block-links-flex">
        <a
          href="http://cryptotraders.com/membership"
          class="perk-link"
          *ngIf="perks"
          >Our perks</a
        >
        <a>Billing & General Support </a>
        <a class="link-bold" href="mailto: hello@xtrades.net"
          >hello@xtrades.net</a
        >
      </div>
      <div class="footer__block-links-flex">
        <a href="http://cryptotraders.com/" class="perk-link" *ngIf="perks"
          >Our platform</a
        >
        <a>Los Angeles, CA 90028</a>
        <a class="link-bold" href="tel:1-530-723-5499">1-530-723-5499</a>
      </div>
      <div class="footer__block-links-flex">
        <a
          href="https://discord.com/channels/542225548982747147/990358149703815178"
          target="_blank"
          class="perk-link"
          *ngIf="perks"
          >Testimonials</a
        >
        <a (click)="showPrivacyPolicy()" class="privacy-link">Privacy Policy</a>
      </div>
      <!-- <div>
        <a >Terms & Conditions</a>
      </div>
      <a >Refund Policy</a> -->
      <!-- <a *ngIf="perks">Our platform</a>
      <a (click)="showPrivacyPolicy()" class="privacy-link">Privacy Policy</a> -->
    </div>

    <div class="footer__block2">
      <div class="copyright">
        © Copyright 2023 - CryptoTraders. All rights reserved.
      </div>
      <div class="social-media">
        <!-- <div class="social-media_item">
          <img src="/assets/fb.svg" alt="facebook-icon" />
        </div> -->
        <div class="social-media_item">
          <a
            href="https://twitter.com/ct_central"
            target="_blank"
            title="Twitter"
            ><img src="/assets/twt.svg" alt="twitter-icon"
          /></a>
        </div>
        <div class="social-media_item">
          <a
            href="https://www.instagram.com/cryptotradersapp/"
            target="_blank"
            title="Instagram"
            ><img src="/assets/ig.svg" alt="instagram-icon"
          /></a>
        </div>
        <!-- <div class="social-media_item">
          <img src="/assets/linked in.svg" alt="linked-icon" />
        </div> -->
      </div>
    </div>
  </div>
</ion-footer>

<app-privacy-policy
  *ngIf="privacyPolicyModal"
  (closePolicy)="closeModal()"
></app-privacy-policy>
