import { ModalController } from '@ionic/angular';
import {
  Component,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent {
  @Output() closePolicy: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private modalCtrl: ModalController,
    private cdr: ChangeDetectorRef
  ) {}


  public close(): void {
    this.closePolicy.emit(true);
  }

  scrollDown(element: HTMLElement): void {
    element.scrollIntoView({ behavior: 'smooth' });
    window.setTimeout(() => {
      this.cdr.markForCheck();
    }, 1000);
  }

  // agreeToPrivacyPolicy() {
  //   this.modalCtrl.dismiss({ success: true });
  // }

  // shouldShowAgreeBtn(element: HTMLElement) {
  //   const offset = 500;
  //   return (
  //     element.parentElement.scrollTop >
  //     element.parentElement.scrollHeight -
  //       element.parentElement.offsetHeight -
  //       offset
  //   );
  // }
}
