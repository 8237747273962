import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NoteModel } from 'src/app/core/models/trader/note';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotesCycleModel } from 'src/app/core/models/trader/notes-сycles';
import { DayEndingPipe } from 'src/app/shared/pipes/day-ending.pipe';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-profile-note-modal',
  templateUrl: './profile-note-modal.component.html',
  styleUrls: ['./profile-note-modal.component.scss'],
})
export class ProfileNoteModalComponent implements OnInit {
  @Input() note: NoteModel;
  @Input() cycle: NotesCycleModel;
  @ViewChild('confirmCloseModal', { static: false })
  confirmCloseModal: TemplateRef<any>;

  public form: FormGroup;
  public text: string;
  public dayEnding = new DayEndingPipe();
  public modalContent: 'close' | 'delete';

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.text = this.note.content.replace(/\n/g, '');
    this.form = this.formBuilder.group({
      text: [this.text || '', Validators.maxLength(2000)],
    });
  }

  public close(): void {
    this.modalContent = 'close';
    if (this.form.dirty) {
      this.modalService.notify(this.confirmCloseModal, 370, 250);
    } else {
      this.confirmClose();
    }
  }

  public confirmClose(): void {
    this.modalService.close();
    this.modalContent === 'close'
      ? this.modalController.dismiss()
      : this.modalController.dismiss({
          action: 'delete',
          noteId: this.note.id,
        });
  }

  public edit(): void {
    this.modalController.dismiss({
      action: 'edit',
      noteId: this.note.id,
      content: this.form.get('text').value,
    });
  }

  public delete(): void {
    this.modalContent = 'delete';
    this.modalService.notify(this.confirmCloseModal, 370, 250);
  }
}
