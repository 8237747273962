<div class="ion-page animate__animated animate__fadeInUp">
  <div class="auth-modal">
    <div class="auth-steps-container">
      <div class="auth-steps-container-cross" (click)="close()"></div>
      <div class="auth-steps-container__title">CryptoTraders</div>

      <!-- Step 1 -->
      <div
        *ngIf="step === 1"
        class="auth-steps-container__step-1"
        [formGroup]="myFormStep1"
      >
        <div class="auth-steps-container__step-counter-wrap">
          <div
            class="auth-steps-container__step-counter-wrap__item-circle"
            [ngStyle]="{ border: '2px solid #81edba' }"
          >
            1
          </div>
          <div class="auth-steps-container__step-counter-wrap__item-dash"></div>
          <div class="auth-steps-container__step-counter-wrap__item-circle">
            2
          </div>
          <!-- <div class="auth-steps-container__step-counter-wrap__item-dash"></div> -->
          <!-- <div class="auth-steps-container__step-counter-wrap__item-circle">
            3
          </div> -->
        </div>
        <div class="auth-steps-container__step-counter-wrap-mob">
          <div
            class="auth-steps-container__step-counter-wrap-mob__item-circle"
            [ngStyle]="{ border: '2px solid #81edba' }"
          ></div>

          <div
            class="auth-steps-container__step-counter-wrap-mob__item-circle"
          ></div>

          <!-- <div
            class="auth-steps-container__step-counter-wrap-mob__item-circle"
          ></div> -->
        </div>
        <label
          for="step-1-input-email"
          class="auth-steps-container__step-1__label-email"
          >Email Address</label
        >
        <input
          type="email"
          id="step-1-input-email"
          class="auth-steps-container__step-1__input-email"
          value="{{ user.data.email }}"
          formControlName="email"
          [ngClass]="{
            'error-input':
              myFormStep1.get('email').invalid &&
              myFormStep1.get('email').touched
          }"
        />
        <div class="warning">
          <div
            *ngIf="
              myFormStep1.get('email').hasError('required') &&
              myFormStep1.get('email').touched
            "
          >
            Email is required
          </div>

          <div *ngIf="alreadyExistingEmail">Email already exists</div>
        </div>

        <div class="auth-steps-container__step-1__names-wrap">
          <div class="auth-steps-container__step-1__names-wrap__item">
            <label class="auth-steps-container__step-1__names-wrap__item__label"
              >Display Name</label
            >
            <input
              type="text"
              class="auth-steps-container__step-1__names-wrap__item__input"
              formControlName="name"
              [ngClass]="{
                'error-input':
                  myFormStep1.get('name').invalid &&
                  myFormStep1.get('name').touched
              }"
            />
          </div>
          <div class="auth-steps-container__step-1__names-wrap__item">
            <label class="auth-steps-container__step-1__names-wrap__item__label"
              >Username</label
            >
            <input
              type="text"
              class="auth-steps-container__step-1__names-wrap__item__input"
              value="{{ user.data.discordUserName }}"
              formControlName="discordName"
              [ngClass]="{
                'error-input':
                  myFormStep1.get('discordName').invalid &&
                  myFormStep1.get('discordName').touched
              }"
            />
          </div>
        </div>
        <div class="warning">
          <div
            *ngIf="
              (myFormStep1.get('discordName').invalid &&
                myFormStep1.get('discordName').touched) ||
              (myFormStep1.get('name').invalid &&
                myFormStep1.get('name').touched)
            "
          >
            Display Name and Username must have at least 2 characters
          </div>
          <div *ngIf="alreadyExistingName">Username already exists</div>
        </div>

        <!-- <input
          type="email"
          id="step-1-input-email"
          class="auth-steps-container__step-1__input-discord-example"
          value="{{ user.data.email }}"
        /> -->
        <button
          (click)="continue()"
          class="auth-steps-container__step-1__continue"
        >
          Continue
        </button>
      </div>

      <!-- Step 2 -->
      <div
        *ngIf="step === 2"
        class="auth-steps-container__step-2"
        (click)="onClick()"
      >
        <div class="auth-steps-container__step-counter-wrap">
          <div class="auth-steps-container__step-counter-wrap__item-circle">
            1
          </div>
          <div class="auth-steps-container__step-counter-wrap__item-dash"></div>
          <div
            class="auth-steps-container__step-counter-wrap__item-circle"
            [ngStyle]="{ border: '2px solid #81edba' }"
          >
            2
          </div>
          <!-- <div class="auth-steps-container__step-counter-wrap__item-dash"></div>
          <div class="auth-steps-container__step-counter-wrap__item-circle">
            3
          </div> -->
        </div>
        <div class="auth-steps-container__step-counter-wrap-mob">
          <div
            class="auth-steps-container__step-counter-wrap-mob__item-circle"
          ></div>

          <div
            class="auth-steps-container__step-counter-wrap-mob__item-circle"
            [ngStyle]="{ border: '2px solid #81edba' }"
          ></div>

          <!-- <div
            class="auth-steps-container__step-counter-wrap-mob__item-circle"
          ></div> -->
        </div>
        <div
          class="error-message"
          [ngStyle]="
            inValidRiskData ? { color: 'red' } : { color: 'transparent' }
          "
          class="warning"
        >
          Please, choose one for each block
        </div>
        <label class="auth-steps-container__step-2__label"
          >What leverage do you use the most?</label
        >
        <div class="auth-steps-container__step-2__leverages-wrap">
          <div
            type="text"
            class="auth-steps-container__step-2__leverages-wrap__item"
            (click)="onClickDiv(div1)"
            #div1
          >
            1X
          </div>
          <div
            type="text"
            class="auth-steps-container__step-2__leverages-wrap__item"
            (click)="onClickDiv(div2)"
            #div2
          >
            3X
          </div>
          <div
            type="text"
            class="auth-steps-container__step-2__leverages-wrap__item"
            (click)="onClickDiv(div3)"
            #div3
          >
            5X
          </div>
          <div
            type="text"
            class="auth-steps-container__step-2__leverages-wrap__item"
            (click)="onClickDiv(div4)"
            #div4
          >
            10X
          </div>
          <div
            type="text"
            class="auth-steps-container__step-2__leverages-wrap__item"
            (click)="onClickDiv(div5)"
            #div5
          >
            50X - 100X
          </div>
        </div>
        <label class="auth-steps-container__step-2__label"
          >What kind of risk taker are you?</label
        >
        <div class="auth-steps-container__step-2__risks-wrap">
          <div
            type="text"
            class="auth-steps-container__step-2__risks-wrap__item"
            (click)="onClickRisk(div6)"
            #div6
          >
            I’m a heavy risk taker, I like going for it
          </div>
          <div
            type="text"
            class="auth-steps-container__step-2__risks-wrap__item"
            (click)="onClickRisk(div7)"
            #div7
          >
            like to stay neutral on risk and reduce when I can but risk when I
            can
          </div>
          <div
            type="text"
            class="auth-steps-container__step-2__risks-wrap__item"
            (click)="onClickRisk(div8)"
            #div8
          >
            I believe trading is a marathon and not a race
          </div>
        </div>
        <div class="auth-steps-container__step-2__buttons-wrap">
          <button
            (click)="back()"
            class="auth-steps-container__step-2__buttons-wrap__back"
          >
            Back
          </button>
          <button
            class="auth-steps-container__step-2__buttons-wrap__continue"
            (click)="signUp()"
          >
            Complete
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
