import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { UpgradePlanParamsStripe } from 'src/app/core/models/plans/upgrade-plan-params-stripe';
import { UpgradePlanModel } from 'src/app/core/models/plans/upgrade-plan.model';

import { PlansService } from 'src/app/core/services/plans.service';
import { ScreenService } from 'src/app/core/services/screen.service';

@Component({
  selector: 'app-upgrade-subscription-modal',
  templateUrl: './upgrade-subscription.component.html',
  styleUrls: ['./upgrade-subscription.component.scss'],
})
export class UpgradeSubscriptionModalComponent implements OnInit {
  @Input() data: UpgradePlanModel;
  public planFeatures: string[];
  public privacyPolicyModal: boolean;
  public isMobile: boolean;
  private subscription = new Subscription();

  constructor(
    private modalController: ModalController,
    private plansService: PlansService,
    public screenService: ScreenService
  ) {}

  ngOnInit(): void {
    this.planFeatures = this.plansService.plans.find(
      (el) => el.title === this.data.newPlan.plan
    ).featuresList;
    this.isMobile = this.screenService.isMobileView;
    this.subscription.add(
      this.screenService.$isMobileSubject.subscribe((data) => {
        this.isMobile = data;
      })
    );
  }

  public close(): void {
    this.modalController.dismiss();
  }

  public confirmUpgrade(): void {
    const params: UpgradePlanParamsStripe = {
      plan: this.data.newPlan.plan,
      subscriptionInterval: this.data.newPlan.subscriptionInterval,
      subscriptionIntervalCount: this.data.newPlan.subscriptionIntervalCount,
    };
    this.modalController.dismiss(params);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.screenService.onResize(event);
  }
}
