import { Injectable, TemplateRef } from '@angular/core';
import { Modal } from '../../../../core/models/modal';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private dataSubject = new BehaviorSubject<Modal>(null);
  public data$: Observable<Modal> = this.dataSubject.asObservable();

  constructor() {}

  public notify(
    template: TemplateRef<any>,
    width: number = 400,
    height: number = 400,
    canClose: boolean = true
  ): void {
    const data = {
      template: template,
      width: width,
      height: height,
      canClose: canClose,
    };
    this.dataSubject.next(data);
  }

  public close(): void {
    this.dataSubject.next(null);
  }
}
