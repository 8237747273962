import { Injectable } from '@angular/core';

import { Observable, lastValueFrom } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

import { HttpClientService } from './http-client.service';
import { getCookie } from '../../shared/utils/helpers';
import { AppStore } from '../state/app/app.store';
import { AuthService } from './auth.service';
import { UserModel } from '../models/user/user';
import { ProfileModel } from '../models/user/user-profile';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClientService, private appStore: AppStore) {}

  async getCurrentUser(): Promise<void> {
    try {
      const user = await lastValueFrom(this.getUserFromApi());
      this.appStore.update({ currentUser: user });
      const role: string[] = jwtDecode(
        getCookie(AuthService.AccessTokenCookieName)
      )['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      const isAdmin = role?.includes('admin') ?? false;
      if (isAdmin) {
        this.appStore.update({ isAdmin: true });
      }
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  }

  private getUserFromApi(): Observable<any> {
    return this.http.get('Users/current');
  }

  public async getUserActivity(
    userId: string,
    page = 1,
    pageSize = 15
  ): Promise<any> {
    const response$ = this.http.get(
      `UserActivity?UserId=${userId}&DateSpec=Always&Page=${page}&pageSize=${pageSize}`
    );
    const response = await lastValueFrom(response$);
    return response;
  }

  public getUserStats(): Observable<any> {
    return this.http.get(`UserScoreCards/current`);
  }

  public getUserEarnings(): Observable<any> {
    return this.http.get(`TopEarnersPayouts/current/total`);
  }

  async checkEmailExist(email: string): Promise<boolean> {
    const response = await this.http
      .post(`Users/checkuseremail?email=${email}`, null)
      .toPromise();
    return response.data;
  }

  async checkNameExist(name: string): Promise<boolean> {
    const response = await this.http
      .post(`Users/checkusername?userName=${name}`, null)
      .toPromise();
    return response.data;
  }

  public updateUser(data: ProfileModel): Observable<UserModel> {
    return this.http.patch('Users', data);
  }
}
