import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertFilterModel } from 'src/app/core/models/alerts/alert-filter';
import { AlertDateSpecType } from 'src/app/core/models/alerts/alerts-date-types';

@Component({
  selector: 'app-filters-modal',
  templateUrl: './filters-modal.component.html',
  styleUrls: ['./filters-modal.component.scss'],
})
export class FiltersModalComponent {
  @Input() filters: AlertFilterModel[] = [];

  constructor(private modalController: ModalController) {}

  public close(data = null): void {
    this.modalController.dismiss(data);
  }
}
