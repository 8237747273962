import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent {
  @Input() background: string = 'rgba(7, 0, 0, 0.62)';
  @Output() hide: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  public hideOverlay(): void {
    this.hide.emit();
  }
}
