<app-overlay (click)="close()"></app-overlay>
<div class="modal">
  <button class="close-btn" (click)="close()"></button>
  <div class="title"></div>
  <div class="filters-wrap">
    <div
      class="filter"
      *ngFor="let filter of filters"
      (click)="close(filter)"
      [ngClass]="{ active: filter.active }"
    >
      {{ filter.title }}
    </div>
  </div>
  <button class="close-btn-bottom" (click)="close()">Close</button>
</div>
