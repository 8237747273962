import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SubscriptionInterval } from 'src/app/core/enums/subscription-interval';
import { DetailsPlanModel, PlanModel } from 'src/app/core/models/plans/plans';
import { PlansService } from 'src/app/core/services/plans.service';

@Component({
  selector: 'app-buy-subscription',
  templateUrl: './buy-subscription.component.html',
  styleUrls: ['./buy-subscription.component.scss'],
})
export class BuySubscriptionComponent implements OnInit {
  @Input() plan: PlanModel;
  @Input() tab: DetailsPlanModel;
  public planSavings: number;
  public subscriptionInterval = SubscriptionInterval;
  public planFullPrice;

  constructor(
    private modalController: ModalController,
    private plansService: PlansService
  ) {}

  ngOnInit(): void {
    this.getPlanFullPrice();
    this.getPlanSavings();
  }

  public close(checkoutPlan = null): void {
    this.modalController.dismiss(checkoutPlan);
  }

  private getPlanFullPrice() {
    const plan = this.plansService.plans.find(
      (elem) => elem.title === this.plan.title
    );
    this.planFullPrice =
      plan?.details.find((el) => {
        return (
          el.subscriptionInterval === this.tab.subscriptionInterval &&
          el.subscriptionIntervalCount === this.tab.subscriptionIntervalCount
        );
      })?.userPrice || this.tab.userPrice;
  }

  private getPlanSavings() {
    let monthCount = { Month: 1, Year: 12 };
    const monthlyPlanPrice = this.plan.details[0].price;
    const fullPricePerPeriod =
      monthlyPlanPrice * monthCount[this.tab.subscriptionInterval];
    const fullPriceWithSavings =
      this.tab.price * monthCount[this.tab.subscriptionInterval];
    this.planSavings =
      100 - Math.trunc((fullPriceWithSavings / fullPricePerPeriod) * 100);
  }
}
