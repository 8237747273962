<div
  class="container"
  [@openClose]="collapsed ? 'closed' : 'open'"
  [style.position]="isMobile && !collapsed ? 'absolute' : 'relative'"
>
  <div class="menu disable-text-select">
    <div
      class="logo pointer"
      [class.logo__full]="!collapsed"
      [class.logo__mini]="collapsed"
      (click)="navigate('')"
      matTooltip="Homepage"
      [matTooltipPosition]="'right'"
    >
      <img src="assets/menu/logo.svg" />
    </div>
    <div
      [class.switcher-collapsed]="collapsed"
      [@openCloseAppSwitcher]="expandedSwitcher ? 'open' : 'closed'"
      [ngClass]="{
        'switcher-small': !expandedSwitcher,
        'switcher-full': expandedSwitcher
      }"
      *ngIf="!collapsed"
    >
      <div
        class="switcher-small-arrows"
        (click)="expandAppSwitcher(true)"
      ></div>
      <div class="logo-expanded-switcher"></div>
      <div class="text" (click)="openSwitchingModal()">Switch Server</div>
      <div class="arrows" (click)="expandAppSwitcher(false)"></div>
    </div>

    <div
      class="switcher-collapsed"
      *ngIf="collapsed && isDesktop"
      (click)="openSwitchingModal()"
    ></div>
    <!-- invite -->
    <!-- <div>
      <button *ngIf="!collapsed" class="invite">
        <img src="assets/menu/invite.svg" alt="human icon" />
        <span>Invite a friend</span>
      </button>
      <button *ngIf="collapsed" class="invite invite-colapsed">
        <img
          src="assets/menu/invite.svg"
          alt="human icon"
          matTooltip="Invite a friend"
          [matTooltipPosition]="'right'"
        />
      </button>
    </div> -->
    <!-- navs -->
    <div *ngIf="!collapsed" class="menu-list">
      <div *ngFor="let item of appPages">
        <app-menu-item [item]="item"></app-menu-item>
      </div>
    </div>

    <div *ngIf="collapsed" class="menu-list menu-list-colapsed">
      <div *ngFor="let item of appPages">
        <div
          *ngIf="item.visible"
          class="pointer"
          matTooltip="{{ item.title }}"
          [matTooltipPosition]="'right'"
          (click)="navigate(item.url)"
        >
          <img
            [src]="'assets/menu/' + item.icon + '.svg'"
            draggable="false"
            width="20"
            class="svg-default"
          />
        </div>
      </div>
    </div>
  </div>
  <div>
    <!-- trial -->
    <div class="trial" *ngIf="!collapsed && !isCurrentUserHasSubscription">
      <img [src]="'assets/menu/x9d.svg'" />
      <div class="trial__text-full">
        Visit our Discord to start your 14 day free trial
      </div>
      <button class="trial__btn" color="primary" (click)="goToDiscrod()">
        Get Started
      </button>
    </div>
    <div
      *ngIf="collapsed"
      class="trial-colapsed pointer"
      matTooltip="Start trial"
      [matTooltipPosition]="'right'"
    >
      <img [src]="'assets/menu/x9d.svg'" width="30" class="svg-default" />
    </div>
    <!-- footer -->
    <div
      class="footer__collapse"
      [ngStyle]="collapsed ? { 'padding-left': '46px' } : {}"
    >
      <div
        class="footer__collapse__container"
        (click)="toggle()"
        *ngIf="isDesktop"
        position="right"
      >
        <img
          [src]="'assets/menu/collapse.svg'"
          class="footer__collapse__icon"
          [class.footer__collapse__icon--collapse]="!collapsed"
          [ngStyle]="collapsed ? { margin: '0 auto' } : {}"
        />
      </div>
      <div *ngIf="!collapsed" style="display: flex; gap: 20px">
        <div
          class="footer__collapse__mute"
          (click)="toggleSounds()"
          position="right"
        >
          <img
            class="footer__collapse__mute__icon"
            [src]="'assets/menu/sound-off.svg'"
            alt="Sound"
          />
        </div>
      </div>
    </div>
  </div>
</div>
