import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.scss'],
})
export class SeparatorComponent {
  @Input() background = 'rgba(255, 255, 255, 0.3)';
  @Input() width = '100%';
}
