import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private router: Router) {}

  openProfilePage(profileId: string = '') {
    this.router.navigate([`/profile/${profileId}`]);
  }
}
