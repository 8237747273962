import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {
  @Input() item = null;
  @Input() activeRouteId: string;

  public collapsed: boolean;
  public isDesktop = false;
  public folderHeight = 41;

  constructor(public menuCtrl: MenuController, private router: Router) {}

  ngOnInit(): void {
    this.calcFolderHeight();
  }

  private calcFolderHeight(): void {
    if (this.item) {
      const children = this.item.children?.filter((x) => x.show)?.length;
      this.folderHeight = !this.item.expanded ? 41 : 41 + children * 41;
    }
  }

  async childClick(event: TouchEvent | MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    // await this.closeMenu();
  }

  navigateMenuItem(url): void {
    this.router.navigate([url]);
  }

  toggleFolder(): void {
    this.item.expanded = !this.item.expanded;
    this.calcFolderHeight();
  }
}

export class AppMenuItem {
  id: string;
  title: string;
  url?: string;
  icon: string;
  notifications?: number;
  show: boolean;
  expanded?: boolean;
  isMobile?: boolean;
  children?: AppMenuItem[];
  visible?: boolean;
}
