<div class="modal ion-page">
  <button class="close-btn" (click)="close()"></button>
  <div class="user-info inner-content">
    <div class="info-wrap" *ngIf="user && user?.profile">
      <div class="row">
        <div class="title">About</div>
      </div>
      <div class="row">
        <div class="column-title">Member Status</div>
        <div class="column">
          {{ user.access === access.plus ? elitePlanTitle : user.access }}
        </div>
      </div>
      <div class="row">
        <div class="column-title">Instagram</div>
        <div class="social-links">
          <div class="column">
            <a [href]="user.profile?.instagramLink" target="_blank">{{
              instagramLink
            }}</a>
          </div>
          <img
            src="assets/dashboard/profile/modal-instagram.svg"
            alt="Instagram icon"
          />
        </div>
      </div>
      <div class="row">
        <div class="column-title">Twitter / X</div>
        <div class="social-links">
          <div class="column">
            <a [href]="user.profile?.twitterLink" target="_blank">{{
              twitterLink
            }}</a>
          </div>
          <img src="assets/dashboard/profile/modal-twitter.svg" alt="X icon" />
        </div>
      </div>
      <div class="row">
        <div class="column-title">Web Link</div>
        <div class="social-links">
          <div class="column">
            <a [href]="user.profile?.websiteLink" target="_blank">{{
              user.profile?.websiteLink?.replace("https://", "").slice(0, 11) +
                "..."
            }}</a>
          </div>
          <img
            src="assets/dashboard/profile/modal-web.svg"
            alt="Website icon"
          />
        </div>
      </div>
      <div class="about-section" *ngIf="user.profile?.about">
        <ion-content class="about" appCustomScrollbar>
          {{ user.profile?.about }}
        </ion-content>
      </div>
    </div>
  </div>
</div>
