import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  public isMobileView = window.innerWidth < 768;
  public $isMobileSubject = new Subject<boolean>();
  constructor() {
    this.$isMobileSubject.next(window.innerWidth < 768);
  }

  onResize(event: Event): void {
    this.isMobileView = window.innerWidth < 768;
    this.$isMobileSubject.next(this.isMobileView);
  }
}
