import { Injectable } from '@angular/core';

import { UserModel } from '../models/user/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  public initIntercom(user: UserModel): void {
    if (user) {
      window.Intercom('boot', {
        app_id: environment.IntercomAppId,
        email: '',
        user_id: 'CryptoTraders ' + user.data.userName,
        created_at: new Date(),
      });
    } else {
      window.Intercom('boot', {
        app_id: environment.IntercomAppId,
      });
    }
  }

  public show(){
    window.Intercom('show');
  }

  public shutdown(): void {
    window.Intercom('shutdown');
  }
}
