<app-overlay *ngIf="data && data.canClose" (hide)="closeModal()"></app-overlay>
<div
  *ngIf="data"
  class="modal animate__animated animate__fadeInUp"
  [ngStyle]="
    !isMobile
      ? {
          width: data.width + 'px',
          height: data.height + 'px',
          left: calcWidth(data.width) + 'px',
          top: calcHeight(data.height) + 'px'
        }
      : {}
  "
>
  <button *ngIf="data.canClose" class="cross" (click)="closeModal()">
    <div class="cross-item"></div>
  </button>
  <ng-template [ngTemplateOutlet]="data.template"></ng-template>
  <button
    *ngIf="data.canClose"
    (click)="closeModal()"
    class="action action-cancel"
  >
    Cancel
  </button>
</div>
