import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appCustomScrollbar]',
})
export class CustomScrollbarDirective implements OnInit {
  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    this.initScrollbar();
  }

  private initScrollbar(): void {
    const stylesheet = `
      ::-webkit-scrollbar {
        height: 4px;
        width: 4px;
        background-color: rgba(255, 255, 255, 0);
      }

      ::-webkit-scrollbar-track {
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background-color: rgba(103, 103, 103, 0.351);
        border-radius: 1px;
      }
    `;

    const styleElmt = this.el.nativeElement.shadowRoot.querySelector('style');

    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      this.el.nativeElement.shadowRoot.appendChild(barStyle);
    }
  }
}
