<div class="top-bar">
  <div class="top-bar_block">
    <img
      class="logo"
      src="../../../assets/logo.svg"
      alt="logo"
      (click)="toHome()"
    />
    <div class="top-bar_block__links">
      <a class="top-bar_block__links__link" (click)="toHome()">Home</a>
      <a class="top-bar_block__links__link" (click)="toTradePage()"
        >Trading Apps</a
      >
      <a class="top-bar_block__links__link" (click)="toCopyTradingBotPage()"
        >Copy Trading</a
      >
      <a
        (click)="toDashboardPage()"
        class="top-bar_block__links__link"
        *ngIf="user"
        >Dashboard</a
      >
    </div>
  </div>
  <div class="top-bar_block">
    <!-- <button class="top-bar_block__invite-btn">
      <img src="/assets/icon/add.svg" alt="add sign" />
      <span>Invite People</span>
    </button> -->
    <!-- <div class="top-bar_block__rate">
      <span><img src="/assets/icon/award-crown.svg" alt="award-crown" /></span>
      <span
        ><div class="top-bar_block__rate-count">85</div>
        <div class="top-bar_block__rate-type">Optimal</div></span
      >
    </div>
    <div class="top-bar_block__bits">
      <span><img src="/assets/icon/bits-logo.svg" alt="Xtrades logo" /></span>
      <span class="top-bar_block__bits-flex-box"
        ><div class="top-bar_block__bits-count">3000 xBits</div>

        <div class="top-bar_block__bits-dollars">300 USD</div>
      </span>
    </div>
    <div class="top-bar_block__separator"></div> -->
    <div class="top-bar_block__profile">
      <span class="top-bar_block__profile-avatar" *ngIf="user">{{
        user.data.discordUserName.split("").slice(0, 2).join("") | uppercase
      }}</span>
      <span class="top-bar_block__profile-name" *ngIf="user">{{
        user.data.discordUserName
      }}</span>
      <!-- <span class="top-bar_block__profile-dropdown"></span> -->
    </div>
  </div>
</div>

<!-- Mobile view -->
<div class="top-bar-mob">
  <div class="top-bar-mob_block">
    <div class="top-bar-mob_block__profile">
      <div class="top-bar-mob_block__profile-avatar" *ngIf="user">
        {{ user.data.discordUserName[0] | uppercase }}
      </div>

      <div>
        <span class="top-bar-mob_block__profile-name" *ngIf="user">{{
          user.data.discordUserName
        }}</span>

        <!-- <div>
          <img
            class="top-bar-mob_block__profile-x"
            src="/assets/icon/bits-logo.svg"
            alt="Xtrades logo"
          />
          <span class="top-bar-mob_block__bits-count">3000 xBits</span>
          <img
            src="/assets/icon/award-crown.svg"
            class="top-bar-mob_block__profile-x"
            alt="award-crown"
          />
          <span class="top-bar-mob_block__rate-count">85</span>
        </div> -->
      </div>
    </div>
    <!-- <div class="top-bar-mob_block__invite"></div> -->
  </div>
</div>
