import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DashboardGuard } from './shared/guards/dashboard.guard';

export const DASHBOARD_URL = '/dashboard';

const routes: Routes = [
  {
    path: '',
    canActivate: [DashboardGuard],
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'signin-discord',
    redirectTo: 'login-redirect/discord',
    pathMatch: 'full',
  },
  {
    path: 'copytrading',
    loadChildren: () =>
      import('./pages/copy-trading-bot/copy-trading-bot.module').then(
        (m) => m.CopyTradingBotModule
      ),
  },
  {
    path: 'login-redirect/:src',
    loadChildren: () => import('./pages/login-redirect/login-redirect.module').then(m => m.LoginRedirectPageModule)
  },
  {
    path: 'sync',
    loadChildren: () =>
      import('./pages/sync-discord/sync-discord.module').then(
        (m) => m.SyncDiscordModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'trade',
    loadChildren: () =>
      import('./pages/trade/trade.module').then((m) => m.TradeModule),
  },
  {
    path: 'plans',
    loadChildren: () =>
      import('./pages/plans/plans.module').then((m) => m.PlansModule),
  },
  {
    path: 'confirmation',
    loadChildren: () =>
      import('./pages/confirmation/confirmation.module').then(
        (m) => m.ConfirmationModule
      ),
  },
  {
    path: 'membership',
    redirectTo: 'plans',
    pathMatch: 'full',
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/user-profile/user-profile.module').then(
        (m) => m.UserProfileModule
      ),
  },

  // { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
