<app-overlay (click)="close()"></app-overlay>
<div class="modal">
  <button class="close-btn" (click)="close()"></button>
  <div class="wrap">
    <div class="small__logo">
      <img src="assets/small-logo.svg" alt="Logo" />
    </div>
    <div class="title">Are you sure you want to cancel?</div>
    <div class="sub-title">
      If you do, you will lose these benefits that you’ve accumulated
    </div>
    <div class="benefits">
      <div class="benefit" *ngFor="let item of benefits; let i = index">
        <div
          class="icon"
          [style.background-image]="
            'url(assets/modals/cancel-subscription/benefit-' + (i + 1) + '.svg)'
          "
        ></div>
        <div class="benefit-text">
          {{ item.text
          }}<span *ngIf="item.button" class="btn">{{ item.button }}</span>
          <span *ngIf="item.smallText" class="small-text">{{
            item.smallText
          }}</span>
        </div>
      </div>
    </div>
    <button class="confirm-btn" (click)="close(true)">
      Yes I do want to cancel
    </button>
  </div>
</div>
