import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { UserModel } from 'src/app/core/models/user/user';
import { AppQuery } from 'src/app/core/state/app/app.query';

@Component({
  selector: 'app-header-membership',
  templateUrl: './header-membership.component.html',
  styleUrls: ['./header-membership.component.scss'],
})
export class HeaderMembershipComponent implements OnInit, OnDestroy {
  public user: UserModel;
  private subscription: Subscription;

  constructor(private router: Router, private appQuery: AppQuery) {}

  ngOnInit(): void {
    this.subscription.add(
      this.appQuery
        .select((x) => x.currentUser)
        .subscribe((user) => {
          this.user = user;
        })
    );
  }

  public toCopyTradingBotPage(): void {
    this.router.navigate(['/copytrading']);
  }

  public toDashboardPage(): void {
    this.router.navigate(['/dashboard']);
  }

  public toHome(): void {
    this.router.navigate(['/home']);
  }

  public goToMemebershipPage(): void {
    this.router.navigate(['/membership']);
  }

  public toTradePage(): void {
    this.router.navigate(['/trade']);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
