import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

import { Observable, catchError } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  constructor(private httpClient: HttpClient) {}

  get<T = any>(url: string): Observable<T> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    return this.httpClient
      .get<T>(`${environment.apiBaseUrl}${url}`, { headers })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          console.log('Failed to load data from the server.');
          throw err;
        })
      );
  }

  post<T = any>(url: string, body: any): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient.post<T>(`${environment.apiBaseUrl}${url}`, body, {
      headers,
    });
  }

  patch<T = any>(url: string, body: any): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient
      .patch<T>(`${environment.apiBaseUrl}${url}`, body, { headers })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          console.log(err.message);
          throw err;
        })
      );
  }

  put<T = any>(url: string, body: any): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient
      .put<T>(`${environment.apiBaseUrl}${url}`, body, { headers })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          console.log(err.message);
          throw err;
        })
      );
  }

  delete<T = any>(url: string, id: any): Observable<T> {
    return this.httpClient
      .delete<T>(`${environment.apiBaseUrl}${url}/${id}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          console.log(err.message);
          throw err;
        })
      );
  }
}
