import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Subscription } from 'rxjs';

import { UserModel } from 'src/app/core/models/user/user';
import { AppQuery } from 'src/app/core/state/app/app.query';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  public user: UserModel;
  public tradingTypes: string[] = ['Day Trading', 'Swing Trading', 'Crypto'];
  private subscription: Subscription = new Subscription();

  constructor(
    private modalController: ModalController,
    private appQuery: AppQuery
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.appQuery
        .select((x) => x.currentUser)
        .subscribe((user) => {
          this.user = user;
        })
    );
  }

  public close(): void {
    this.modalController.dismiss();
  }
}
