import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { DashboardConstants } from '../constants/dashboard.constants';

@Injectable({
  providedIn: 'root',
})
class PermissionsService {
  constructor(private authService: AuthService, private router: Router, ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (this.authService.isAuthenticated && !sessionStorage.getItem(DashboardConstants.isHomeButtonClicked)) {
      return this.router.createUrlTree(['/dashboard']);
    } else {
      return true;
    }
  } 
}

export const DashboardGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree => {
  return inject(PermissionsService).canActivate(next, state);
};