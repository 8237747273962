<app-overlay (click)="close()" *ngIf="plan"></app-overlay>
<div class="modal ion-page" *ngIf="plan">
  <div class="inner-content">
    <button class="close-btn" (click)="close()"></button>
    <div class="wrap">
      <div
        class="icon"
        [ngStyle]="{
          'background-image':
            'url(\'/assets/icon/' + plan.title.toLowerCase() + '-icon.svg\')',
          'background-size': 'contain'
        }"
      ></div>
      <div
        class="period"
        *ngIf="tab.subscriptionIntervalCount !== 0; else lifetimePeriod"
      >
        {{ tab.subscriptionIntervalCount }} {{ tab.subscriptionInterval }}
      </div>
      <ng-template #lifetimePeriod>
        <div class="period">Lifetime</div></ng-template
      >

      <div class="price">${{ planFullPrice }}</div>
      <div
        class="savings"
        *ngIf="
          !(
            tab.subscriptionIntervalCount === 1 &&
            tab.subscriptionInterval === subscriptionInterval.Month
          ) && tab.subscriptionIntervalCount !== 0
        "
      >
        Pay Per Month (Saving
        {{ planSavings }}%!)
      </div>
      <div class="info" *ngIf="tab.subscriptionIntervalCount !== 0">
        {{ tab.subscriptionIntervalCount }}
        {{ tab.subscriptionInterval }} Paid in Full. Recurring subscription
        thereafter until cancelled.
      </div>
      <app-separator [width]="'194px'"></app-separator>
      <div class="features-wrap">
        <div class="features-wrap__item" *ngFor="let item of plan.features">
          {{ item.value }}
        </div>
      </div>
      <button class="close-btn-bottom" (click)="close(tab)">continue</button>
    </div>
  </div>
</div>
