<div class="container animate__animated animate__fadeInUp">
  <div class="container__cross" (click)="close()"></div>
  <ion-content class="privacy-policy-modal" appCustomScrollbar>
    <div class="privacy-policy-modal__header">
      <div class="privacy-policy-modal__header__icon">
        <ion-icon slot="icon-only" src="assets/icon/favicon.png"></ion-icon>
      </div>
      <div class="privacy-policy-modal__header__content">
        <div class="privacy-policy-modal__header__content__title">
          Terms of Service & Privacy Policy
        </div>
        <div class="privacy-policy-modal__header__content__subtitle">
          Last updated: September 15, 2022
        </div>
      </div>
    </div>

    <div class="privacy-policy-modal">
      <div class="privacy-policy-modal__content">
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <h2 class="elementor-heading-title elementor-size-default">
              Refund and Return Policy
            </h2>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <div>
              <p>
                All sales are final, and that is why we provide a 14 day free
                trial of our Elite Monthly plan to allow users the ability to
                understand and know exactly what they are getting before they
                make a purchase to continue. Refunds are only available in the
                cases of duplicate payments or overcharges. Your payment data is
                encrypted and secure. All amounts shown are in USD.
              </p>
            </div>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <h2 class="elementor-heading-title elementor-size-default">
              Additional terms for monthly and annual recurring subscriptions
            </h2>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <div>
              <p>
                You will automatically be charged at your renewal date unless
                cancelled. Cancellations must be made or requested before the
                renewal date and time. Renewal payments occur on the same date
                of the month and time of the day as your initial sign-up date
                and time.
              </p>
            </div>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <h2 class="elementor-heading-title elementor-size-default">
              Premium Item Access
            </h2>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <div>
              <p>
                When you purchase our Yearly or Lifetime membership, you are
                gaining access to our database of ThinkorSwim, Trade-Ideas and
                Tradingview scripts, thus once the purchase is made, we are not
                able to provide a refund for your purchase or reverse the
                purchase.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h2 class="privacy-policy-modal__section__header first-paragraph">
            TERMS AND CONDITIONS
          </h2>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <div class="privacy-policy-modal__section__body">
              <p>
                The website(s) located at&nbsp;<a
                  href="https://www.cryptotraders.com/"
                  target="_blank"
                  rel="external nofollow noopener"
                  >www.cryptotraders.com</a
                >&nbsp;is operated by X TRADES LLC. These terms and conditions
                apply to your visit to and use of the website(s) as well as the
                social medias operated by X TRADES LLC and discord servers. The
                terms and conditions of use should be read carefully before you
                use the website(s), social medias, and/or discord servers. Your
                use of the website(s), social medias, and/or discord server
                constitutes your acceptance of the terms and conditions, as well
                as our privacy policy.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h2 class="privacy-policy-modal__section__header">
            1. USE CONTENT AT USER’S OWN RISK
          </h2>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <div class="privacy-policy-modal__section__body">
              <p>
                X TRADES LLC does not provide investment or financial advice.
                Nor does X TRADES LLC provide legal, tax or accounting advice-or
                advice regarding the suitability or profitability of a security
                or investment. The content on the website(s)s, discord servers,
                and/or social medias are solely for educational purposes and
                entertainment purposes only. The information on the website(s)
                is published generally—and is not personal to you, nor does it
                take into account your personal circumstances. Your use of the
                content on the website(s), social medias, and/or discord server
                is entirely at your own risk and it is your sole responsibility
                to evaluate the accuracy, completeness and usefulness of the
                content. X TRADES LLC encourages you to seek the advice of a
                qualified financial advisor before making financial decisions or
                investments.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h2 class="privacy-policy-modal__section__header">
            2. NO WARRANTY / NO GUARANTEE
          </h2>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <div class="privacy-policy-modal__section__body">
              <p>
                The information on the website(s), social medias, and/or discord
                server should not be intended to provide investment, tax or
                other financial related advice. The information on the
                website(s), social medias, and/or discord server should not be
                considered a substitute for professional financial advice. If
                you choose to engage in transactions based upon the content
                found on the website(s), social medias, and/or discord server,
                those decisions and transactions and any consequences flowing
                therefrom are your sole responsibility. X TRADES LLC. does not
                provide investment advice directly, indirectly, implicitly, or
                in any manner whatsoever. You should use any information found
                on the website(s), social medias, and/or discord server merely
                as a starting point for your own independent research.
              </p>
              <p>
                X TRADES LLC. does not guarantee the accuracy of data found on
                the website(s), social medias, and/or discord server. The
                material available on the website(s), social medias, and/or
                discord server has been produced by multiple independent
                sources. Any opinions or recommendations expressed in any
                third-party link, are solely those of the independent providers
                and are not the opinions or recommendations of X TRADES LLC.
                Although X TRADES LLC makes every effort to ensure the accuracy
                of information on the website(s), social medias, and/or discord
                server, such accuracy is not guaranteed. You should always
                verify the accuracy of data by analyzing a company’s SEC filings
                before making any investment decisions.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h2 class="privacy-policy-modal__section__header">
            3. LIMITATION OF LIABILITY
          </h2>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <div class="privacy-policy-modal__section__body">
              <p>
                Under no circumstances shall X TRADES LLC be liable for any
                indirect, direct, incidental, special, punitive or consequential
                damages that result in any way from your use, non-use, or
                reliance upon the content on the website(s), social medias,
                and/or discord server. The content on the website(s) and/or
                discord server may contain links to other website(s)s. X TRADES
                LLC. does not endorse the content on those website(s)s and will
                not be held responsible for the content on those website(s)s.
                The content on the website(s) and/or discord server is for
                general information only and is not intended to be relied upon
                by users in either making or not making specific investment
                decisions. the website(s), social medias, and/or discord server
                may contain advertisements that may direct you to other third-
                party website(s)s. X TRADES LLC is not affiliated with those
                third-party website(s)s. The display of advertisements on the
                website(s), social medias, and/or discord server does not
                constitute an endorsement by X TRADES LLC. of the products or
                the services offered by these third parties.
              </p>
              <p>
                The website(s), social medias, and/or discord server may contain
                links to articles or commentary. This is provided solely as a
                convenience. The information contained in these articles or
                commentary is not endorsed by X TRADES LLC. Nor is the accuracy
                guaranteed by X TRADES LLC. As such, X TRADES, LLC. is not
                responsible for the content in such commentary or articles.Any
                information contained in these articles does not constitute a
                recommendation by X TRADES LLC. to buy, sell or hold any
                security, or financial product or financial instrument discussed
                in the articles. Additionally, the information contained in
                these articles does not constitute an “offer” or a “solicitation
                of an offer”.
              </p>
              <p>
                Information found on the website(s), social medias, and/or
                discord server may have been obtained from published sources
                which were prepared by third parties. While it is believed that
                such sources are reliable, X TRADES LLC. does not assume any
                responsibility for the accuracy or completeness of such
                information.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h2 class="privacy-policy-modal__section__header">
            4. NO ASSURANCE OR EXPECTATIONS
          </h2>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <div class="privacy-policy-modal__section__body">
              <p>
                All statements other than historical fact statements which
                include words such as “will,” “should,” “estimate,” “believe,”
                “may,” “anticipate,” “expect,” etc. must be considered as
                forward-looking statements. X TRADES LLC. believes that the
                expectations reflected in these forward-looking statements are
                reasonable. X TRADES LLC, can give no assurance as to the
                correctness or accuracy of these expectations. Many factors may
                affect actual results and some individuals may have materially
                different investment results.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h2 class="privacy-policy-modal__section__header">PRIVACY POLICY</h2>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <div class="privacy-policy-modal__section__body">
              <p>
                This Privacy Policy describes our policies and procedures on the
                collection, use and disclosure of Your information when You use
                the Service and tells You about Your privacy rights and how the
                law protects You.
              </p>
              <p>
                We use Your Personal data to provide and improve the Service. By
                using the Service, You agree to the collection and use of
                information in accordance with the Privacy Policy. The Privacy
                Policy has been created with the help of the Privacy Policy
                Generator.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <h2 class="privacy-policy-modal__section__header">
              INTERPRETATION AND DEFINITIONS
            </h2>
          </div>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <h3 class="privacy-policy-modal__section__header">
              Interpretation
            </h3>
          </div>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <div class="privacy-policy-modal__section__body">
              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <h3 class="privacy-policy-modal__section__header">Definitions</h3>
          </div>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <div class="privacy-policy-modal__section__body">
              <p>For the purposes of the Privacy Policy:</p>
              <ul>
                <li>
                  <p>
                    <strong>Account</strong>&nbsp;means a unique account created
                    for You to access our Service or parts of our Service.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Company</strong>&nbsp;(referred to as either “the
                    Company”, “We”, “Us” or “our” in this Agreement) refers to X
                    TRADES LLC. and Discord.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cookies</strong>&nbsp;are small files that are
                    placed on Your computer, mobile device or any other device
                    by a website(s), containing the details of Your browsing
                    history on that website(s) among its many uses.
                  </p>
                </li>
                <li>
                  <p><strong>Country</strong>&nbsp;refers to: United States</p>
                </li>
                <li>
                  <p>
                    <strong>Device</strong>&nbsp;means any device that can
                    access the Service such as a computer, a cell phone or a
                    digital tablet.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Personal Data</strong>&nbsp;is any information that
                    relates to an identified or identifiable individual.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Service</strong>&nbsp;refers to the website(s)
                    and/or Discord.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Service Provider</strong>&nbsp;means any natural or
                    legal person who processes the data on behalf of the
                    Company. It refers to third-party companies or individuals
                    employed by the Company to facilitate the Service, to
                    provide the Service on behalf of the Company, to perform
                    services related to the Service or to assist the Company in
                    analyzing how the Service is used.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Third-party Social Media Service</strong
                    >&nbsp;refers to any website(s) or any social network
                    website(s) through which a User can log in or create an
                    account to use the Service.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Usage Data</strong>&nbsp;refers to data collected
                    automatically, either generated by the use of the Service or
                    from the Service infrastructure itself (for example, the
                    duration of a page visit).
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Website</strong>&nbsp;refers to X TRADES LLC,
                    accessible from&nbsp;<a
                      href="https://www.cryptotraders.com/"
                      target="_blank"
                      rel="external nofollow noopener"
                      >www.cryptotraders.com</a
                    >
                  </p>
                </li>
                <li>
                  <p>
                    <strong>You</strong>&nbsp;means the individual accessing or
                    using the Service, or the company, or other legal entity on
                    behalf of which such individual is accessing or using the
                    Service, as applicable.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <h2 class="privacy-policy-modal__section__header">
              COLLECTING AND USING YOUR PERSONAL DATA
            </h2>
          </div>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <h3 class="privacy-policy-modal__section__header">
              TYPES OF DATA COLLECTED
            </h3>
          </div>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <h4 class="privacy-policy-modal__section__header">PERSONAL DATA</h4>
          </div>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <div class="privacy-policy-modal__section__body">
              <p>
                While using our Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. Personally identifiable information may
                include, but is not limited to:
              </p>
              <ul>
                <li><p>Email address</p></li>
                <li><p>First name and last name</p></li>
                <li><p>Phone number</p></li>
                <li><p>Address, State, Province, ZIP/Postal code, City</p></li>
                <li><p>Usage Data</p></li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <h4 class="privacy-policy-modal__section__header">Usage Data</h4>
          </div>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <div class="privacy-policy-modal__section__body">
              <p>
                Usage Data is collected automatically when using the Service.
              </p>
              <p>
                Usage Data may include information such as Your Device’s
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that You visit, the
                time and date of Your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </p>
              <p>
                When You access the Service by or through a mobile device, We
                may collect certain information automatically, including, but
                not limited to, the type of mobile device You use, Your mobile
                device unique ID, the IP address of Your mobile device, Your
                mobile operating system, the type of mobile Internet browser You
                use, unique device identifiers and other diagnostic data.
              </p>
              <p>
                We may also collect information that Your browser sends whenever
                You visit our Service or when You access the Service by or
                through a mobile device.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <h4 class="privacy-policy-modal__section__header">
              Information from Third-Party Social Media Services
            </h4>
          </div>
        </div>
        <div>
          <div class="privacy-policy-modal__section">
            <div class="privacy-policy-modal__section__body">
              <p>
                The Company allows You to create an account and log in to use
                the Service through the following Third-party Social Media
                Services:
              </p>
              <ul>
                <li>Discord</li>
                <li>Google</li>
                <li>Apple</li>
              </ul>
              <p>
                If You decide to register through or otherwise grant us access
                to a Third-Party Social Media Service, We may collect Personal
                data that is already associated with Your Third-Party Social
                Media Service’s account, such as Your name, Your email address,
                Your activities or Your contact list associated with that
                account.
              </p>
              <p>
                You may also have the option of sharing additional information
                with the Company through Your Third-Party Social Media Service’s
                account. If You choose to provide such information and Personal
                Data, during registration or otherwise, You are giving the
                Company permission to use, share, and store it in a manner
                consistent with this Privacy Policy.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="privacy-policy-modal__section" #target>
            <h4 class="privacy-policy-modal__section__header">
              Tracking Technologies and Cookies
            </h4>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <div>
              <p>
                We may use Cookies and similar tracking technologies to track
                the activity on our Service and store certain information.
                Tracking technologies used are beacons, tags, and scripts to
                collect and track information and to improve and analyze our
                Service. The technologies We use may include:
              </p>
              <ul>
                <li>
                  <strong>Cookies or Browser Cookies.</strong>&nbsp;A cookie is
                  a small file placed on Your Device. You can instruct Your
                  browser to refuse all Cookies or to indicate when a Cookie is
                  being sent. However, if You do not accept Cookies, You may not
                  be able to use some parts of our Service. Unless you have
                  adjusted Your browser setting so that it will refuse Cookies,
                  our Service may use Cookies.
                </li>
                <li>
                  <strong>Flash Cookies.</strong>&nbsp;Certain features of our
                  Service may use local stored objects (or Flash Cookies) to
                  collect and store information about Your preferences or Your
                  activity on our Service. Flash Cookies are not managed by the
                  same browser settings as those used for Browser Cookies. For
                  more information on how You can delete Flash Cookies, please
                  read “Where can I change the settings for disabling, or
                  deleting local shared objects?” available at&nbsp;<a
                    href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html"
                    target="_blank"
                    rel="noopener"
                    >www.adobe.com</a
                  >
                </li>
                <li>
                  <strong>Web Beacons.</strong>&nbsp;Certain sections of our
                  Service and our emails may contain small electronic files
                  known as web beacons (also referred to as clear gifs, pixel
                  tags, and single-pixel gifs) that permit the Company, for
                  example, to count users who have visited those pages or opened
                  an email and for other related website(s) statistics (for
                  example, recording the popularity of a certain section and
                  verifying system and server integrity).
                </li>
              </ul>
              <p>
                Cookies can be “Persistent” or “Session” Cookies. Persistent
                Cookies remain on Your personal computer or mobile device when
                You go offline, while Session Cookies are deleted as soon as You
                close Your web browser. Learn more about cookies:&nbsp;<a
                  href="https://www.termsfeed.com/blog/cookies/"
                  target="_blank"
                  rel="noopener"
                  >What Are Cookies?</a
                >.
              </p>
              <p>
                We use both Session and Persistent Cookies for the purposes set
                out below:
              </p>
              <ul>
                <li>
                  <p><strong>Necessary / Essential Cookies</strong></p>
                  <p>Type: Session Cookies</p>
                  <p>Administered by: Us</p>
                  <p>
                    Purpose: These Cookies are essential to provide You with
                    services available through the website(s) and to enable You
                    to use some of its features. They help to authenticate users
                    and prevent fraudulent use of user accounts. Without these
                    Cookies, the services that You have asked for cannot be
                    provided, and We only use these Cookies to provide You with
                    those services.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cookies Policy / Notice Acceptance Cookies</strong>
                  </p>
                  <p>Type: Persistent Cookies</p>
                  <p>Administered by: Us</p>
                  <p>
                    Purpose: These Cookies identify if users have accepted the
                    use of cookies on the website(s).
                  </p>
                </li>
                <li>
                  <p><strong>Functionality Cookies</strong></p>
                  <p>Type: Persistent Cookies</p>
                  <p>Administered by: Us</p>
                  <p>
                    Purpose: These Cookies allow us to remember choices You make
                    when You use the website(s), such as remembering your login
                    details or language preference. The purpose of these Cookies
                    is to provide You with a more personal experience and to
                    avoid You having to re-enter your preferences every time You
                    use the website(s).
                  </p>
                </li>
              </ul>
              <p>
                For more information about the cookies we use and your choices
                regarding cookies, please visit our Cookies Policy or the
                Cookies section of our Privacy Policy.
              </p>
            </div>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__header">
            <h3>Use of Your Personal Data</h3>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <div>
              <p>
                The Company may use Personal Data for the following purposes:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>To provide and maintain our Service</strong>,
                    including to monitor the usage of our Service.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>To manage Your Account:</strong>&nbsp;to manage Your
                    registration as a user of the Service. The Personal Data You
                    provide can give You access to different functionalities of
                    the Service that are available to You as a registered user.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>For the performance of a contract:</strong>&nbsp;the
                    development, compliance and undertaking of the purchase
                    contract for the products, items or services You have
                    purchased or of any other contract with Us through the
                    Service.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>To contact You:</strong>&nbsp;To contact You by
                    email, telephone calls, SMS, or other equivalent forms of
                    electronic communication, such as a mobile application’s
                    push notifications regarding updates or informative
                    communications related to the functionalities, products or
                    contracted services, including the security updates, when
                    necessary or reasonable for their implementation.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>To provide You</strong>&nbsp;with news, special
                    offers and general information about other goods, services
                    and events which we offer that are similar to those that you
                    have already purchased or enquired about unless You have
                    opted not to receive such information.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>To manage Your requests:</strong>&nbsp;To attend and
                    manage Your requests to Us.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>For business transfers:</strong>&nbsp;We may use
                    Your information to evaluate or conduct a merger,
                    divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of our assets, whether
                    as a going concern or as part of bankruptcy, liquidation, or
                    similar proceeding, in which Personal Data held by Us about
                    our Service users is among the assets transferred.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>For other purposes</strong>: We may use Your
                    information for other purposes, such as data analysis,
                    identifying usage trends, determining the effectiveness of
                    our promotional campaigns and to evaluate and improve our
                    Service, products, services, marketing and your experience.
                  </p>
                </li>
              </ul>
              <p>
                We may share Your personal information in the following
                situations:
              </p>
              <ul>
                <li>
                  <strong>With Service Providers:</strong>&nbsp;We may share
                  Your personal information with Service Providers to monitor
                  and analyze the use of our Service, to contact You.
                </li>
                <li>
                  <strong>For business transfers:</strong>&nbsp;We may share or
                  transfer Your personal information in connection with, or
                  during negotiations of, any merger, sale of Company assets,
                  financing, or acquisition of all or a portion of our business
                  to another company.
                </li>
                <li>
                  <strong>With Affiliates:</strong>&nbsp;We may share Your
                  information with our affiliates, in which case we will require
                  those affiliates to honor the Privacy Policy. Affiliates
                  include our parent company and any other subsidiaries, joint
                  venture partners or other companies that We control or that
                  are under common control with Us.
                </li>
                <li>
                  <strong>With business partners:</strong>&nbsp;We may share
                  Your information with our business partners to offer You
                  certain products, services or promotions.
                </li>
                <li>
                  <strong>With other users:</strong>&nbsp;when You share
                  personal information or otherwise interact in the public areas
                  with other users, such information may be viewed by all users
                  and may be publicly distributed outside. If You interact with
                  other users or register through a Third-Party Social Media
                  Service, Your contacts on the Third-Party Social Media Service
                  may see Your name, profile, pictures and description of Your
                  activity. Similarly, other users will be able to view
                  descriptions of Your activity, communicate with You and view
                  Your profile.
                </li>
                <li>
                  <strong>With Your consent</strong>: We may disclose Your
                  personal information for any other purpose with Your consent.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__header">
            <h3>Retention of Your Personal Data</h3>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <div>
              <p>
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in the Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
              </p>
              <p>
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when the data is used to strengthen the security
                or to improve the functionality of our Service, or We are
                legally obligated to retain the data for longer time periods.
              </p>
            </div>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__header">
            <h3>Transfer of Your Personal Data</h3>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <div>
              <p>
                Your information, including Personal Data, is processed at the
                Company’s operating offices and in any other places where the
                parties involved in the processing are located. It means that
                the information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction.
              </p>
              <p>
                Your consent to the Privacy Policy followed by Your submission
                of such information represents Your agreement to that transfer.
              </p>
              <p>
                The Company will take all steps reasonably necessary to ensure
                that Your data is treated securely and in accordance with the
                Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                personal information.
              </p>
            </div>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__header">
            <h3>Disclosure of Your Personal Data</h3>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <h4 class="elementor-heading-title elementor-size-default">
              Business Transactions
            </h4>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <div>
              <p>
                If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </p>
            </div>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <h4 class="elementor-heading-title elementor-size-default">
              Law enforcement
            </h4>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <div>
              <p>
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency).
              </p>
            </div>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <h4 class="elementor-heading-title elementor-size-default">
              OTHER LEGAL REQUIREMENTS
            </h4>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <div>
              <p>
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to:
              </p>
              <ul>
                <li>Comply with a legal obligation</li>
                <li>
                  Protect and defend the rights or property of the Company
                </li>
                <li>
                  Prevent or investigate possible wrongdoing in connection with
                  the Service
                </li>
                <li>
                  Protect the personal safety of Users of the Service or the
                  public
                </li>
                <li>Protect against legal liability</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <h4 class="elementor-heading-title elementor-size-default">
              Security of Your Personal Data
            </h4>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <div>
              <p>
                The security of Your Personal Data is important to Us, but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While We strive to
                use commercially acceptable means to protect Your Personal Data,
                We cannot guarantee its absolute security.
              </p>
            </div>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <h2 class="elementor-heading-title elementor-size-default">
              Links to Other website(s)s
            </h2>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <div>
              <p>
                Our Service may contain links to other website(s)s that are not
                operated by Us. If You click on a third party link, You will be
                directed to that third party’s site. We strongly advise You to
                review the Privacy Policy of every site You visit.
              </p>
              <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>
            </div>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <h2 class="elementor-heading-title elementor-size-default">
              Changes to the Privacy Policy
            </h2>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <div>
              <p>
                We may update our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                the page.
              </p>
              <p>
                We will let You know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update the
                “Last updated” date at the top of the Privacy Policy.
              </p>
              <p>
                You are advised to review the Privacy Policy periodically for
                any changes. Changes to the Privacy Policy are effective when
                they are posted on the page.
              </p>
            </div>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <h2 class="elementor-heading-title elementor-size-default">
              Contact Us
            </h2>
          </div>
        </div>
        <div class="privacy-policy-modal__section">
          <div class="privacy-policy-modal__section__body">
            <div>
              <p>
                If you have any questions about the Privacy Policy, You can
                contact us by email:&nbsp;<a
                  href="mailto:hello@enhancedinvestor.com"
                  >hello@enhancedinvestor.com</a
                >
              </p>
            </div>
          </div>
        </div>
        <div #scrollTarget></div>
      </div>
      <!-- <div class="privacy-policy-modal__scroll-btn">
      <ion-button
        *ngIf="!shouldShowAgreeBtn(scrollTarget)"
        (click)="scrollDown(scrollTarget)"
        color="primary"
      >
        <ion-icon name="arrow-down"></ion-icon>
      </ion-button>
    </div> -->
    </div>
    <!-- <div class="privacy-policy-modal__footer" footer>
      <ion-button
        fill="outline"
        color="primary"
        (click)="close()"
        type="button"
      >
        Cancel
      </ion-button>
      <ion-button (click)="agreeToPrivacyPolicy()" color="primary">
        Accept
      </ion-button>
    </div> -->
  </ion-content>
</div>
