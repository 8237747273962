import { Component, OnDestroy } from '@angular/core';
import { ModalService } from './modal.service';
import { Modal } from 'src/app/core/models/modal';
import { Subscription } from 'rxjs';
import { ScreenService } from 'src/app/core/services/screen.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnDestroy {
  public data: Modal;
  public isMobile: boolean;
  private subscription: Subscription = new Subscription();

  constructor(
    public modalService: ModalService,
    private screenService: ScreenService
  ) {
    this.subscription.add(
      this.modalService.data$.subscribe((data) => {
        this.data = data;
        this.isMobile = this.screenService.isMobileView;
      })
    );
  }

  public calcWidth(data: number): number {
    return window.innerWidth / 2 - data / 2;
  }

  public calcHeight(data: number): number {
    return window.innerHeight / 2 - data / 2;
  }

  public closeModal(): void {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
