import { Injectable } from '@angular/core';
import { ToastController, ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastController: ToastController
  ) {}

  async presentToast(messageData: string, status: string): Promise<void> {
    let statusIcon: string;
    let statusColor: string;
    if (status === 'error') {
      statusIcon = 'alert';
      statusColor = 'danger';
    } else if (status === 'success') {
      statusIcon = 'chevron-down';
      statusColor = 'secondary';
    }
    const toast = await this.toastController.create({
      message: messageData,
      duration: 5000,
      icon: statusIcon,
      position: 'top',
      cssClass: 'toast-' + status,
      buttons: [
        {
          icon: 'close',
          htmlAttributes: {
            'aria-label': 'close',
          },
          cssClass: 'toast-button',
        },
      ],
      color: statusColor,
    });
    await toast.present();
  }
}
