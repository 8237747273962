<app-overlay (click)="close()"></app-overlay>

<ng-container *ngIf="!isMobile; else mobileView">
  <div class="modal ion-page">
    <div class="inner-content">
      <div class="top-block">
        <div class="title">Upgrade membership plan</div>
        <button class="close-btn" (click)="close()"></button>
      </div>
      <app-separator class="main"></app-separator>
      <div class="wrap">
        <div class="features">
          <div class="features-wrap">
            <div class="plans">
              <div class="plan-wrap">
                <div
                  class="icon"
                  [ngStyle]="{
                    'background-image':
                      'url(\'/assets/icon/' +
                      data.currentPlan.plan.toLowerCase() +
                      '-icon.svg\')',
                    'background-size': 'contain'
                  }"
                ></div>
              </div>
              <div class="arrows"></div>
              <div class="plan-wrap">
                <div
                  class="icon"
                  [ngStyle]="{
                    'background-image':
                      'url(\'/assets/icon/' +
                      data.newPlan.plan.toLowerCase() +
                      '-icon.svg\')',
                    'background-size': 'contain'
                  }"
                ></div>
              </div>
            </div>
            <div class="plans-details">
              <div class="plan-wrap">
                <div class="plan-current">
                  <div class="title">
                    {{
                      data.currentPlan.plan === "Plus"
                        ? "Elite Plus"
                        : data.currentPlan.plan
                    }}
                  </div>
                  <div class="title-text">Days left</div>
                  <div class="days-count">
                    {{ data.currentPlan.daysLeft }} days
                  </div>
                </div>
              </div>
              <div class="plan-wrap">
                <div class="plan-upgrade">
                  <div class="title">
                    {{
                      data.newPlan.plan === "Plus"
                        ? "Elite Plus"
                        : data.newPlan.plan
                    }}
                  </div>
                  <div class="title-text">Days convert</div>
                  <div class="days-count">{{ data.newPlan.daysLeft }} days</div>
                </div>
              </div>
            </div>
          </div>
          <app-separator></app-separator>
          <ul class="features-list">
            <li class="feature" *ngFor="let item of planFeatures">
              {{ item }}
            </li>
          </ul>
        </div>

        <div class="payment">
          <div class="top">
            <div class="title">Payable today</div>
            <div class="sum">$0.00</div>
            <div class="sub-text">
              Your {{ data.currentPlan.daysLeft }} days of
              {{ data.currentPlan.plan }} plan converts to
              {{ data.newPlan.daysLeft }} days of {{ data.newPlan.plan }} plan
            </div>
            <app-separator class="separator"></app-separator>
            <div class="title">
              Payable on
              {{ data.nextBilling.billingDate | date : "LLLL dd, yyyy" }}
            </div>
            <div class="sum">$ {{ data.nextBilling.price }}</div>
            <div class="sub-text">
              {{ data.newPlan.plan }} plan, Tax included
            </div>
            <div class="info-block">
              <div class="info-icon">
                <img
                  src="../../../../../assets/modals/upgrade/info-icon.svg"
                  alt="Info Icon"
                />
              </div>

              <div class="title">You won't be billed today</div>
              <div class="text">
                We'll just update the number of days on your new subscription.
              </div>
            </div>
          </div>

          <div class="bottom">
            <div class="agreement">
              By clicking button below you are agree to our
              <a (click)="privacyPolicyModal = true" class="privacy-link"
                >Terms of Service and Privacy Policy</a
              >
            </div>
            <button class="upgrade-btn" (click)="confirmUpgrade()">
              continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #mobileView
  ><app-mob-upgrade-subscription
    [data]="data"
    [planFeatures]="planFeatures"
    (onTermsShow)="privacyPolicyModal = true"
    (onUpgrade)="confirmUpgrade()"
    (onClose)="close()"
  ></app-mob-upgrade-subscription
></ng-template>

<app-privacy-policy
  *ngIf="privacyPolicyModal"
  (closePolicy)="privacyPolicyModal = false"
></app-privacy-policy>
