<div
  class="menu-folder"
  *ngIf="item?.children?.length > 0"
  (click)="toggleFolder()"
  [style.height.px]="folderHeight"
>
  <div class="menu-folder__item">
    <div
      class="is-active"
      [class.is-active--active-folder]="item.expanded"
    ></div>
    <div
      class="list-item list-item--folder"
      [class.list-item--expanded]="item.expanded"
      position="right"
      [class.menu-folder__item--expanded]="item.expanded"
      [class.list-item--not-active]="activeRouteId !== item.id"
    >
      <img
        [src]="'assets/menu/' + item.icon + '.svg'"
        draggable="false"
        class="svg-default"
      />
      <div class="list-item__title">
        {{ item.title }}
      </div>
    </div>

    <div
      class="menu-folder__item__chevron"
      [class.menu-folder__item__chevron--expanded]="item.expanded"
    >
      <ion-icon [name]="'chevron-forward-outline'"></ion-icon>
    </div>
  </div>

  <ng-container *ngIf="item.expanded">
    <ng-container *ngFor="let child of item.children">
      <ng-container *ngIf="child.show">
        <div
          class="menu-folder__item"
          routerDirection="root"
          (click)="childClick($event)"
        >
          <div
            class="is-active"
            [class.is-active--active-item]="activeRouteId === child.id"
          ></div>
          <div
            class="list-item list-item--folder list-item--folder-child"
            [class.menu-folder__item--expanded]="item.expanded"
            [class.list-item--menu-collapsed]="collapsed && isDesktop"
            position="right"
            [class.list-item--folder-child-selected]="
              activeRouteId === child.id
            "
            [class.list-item--not-active]="activeRouteId !== child.id"
          >
            <img
              [src]="'assets/menu/' + child.icon + '.svg'"
              class="svg-default"
              draggable="false"
            />
            <div
              *ngIf="!collapsed || !isDesktop"
              class="list-item__title list-item__title--child"
            >
              {{ child.title }}
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<div class="menu-single" *ngIf="item?.visible">
  <div
    class="is-active"
    [class.is-active--active-item]="activeRouteId === item.id"
  ></div>
  <div
    class="list-item"
    [class.list-item--menu-collapsed]="collapsed && isDesktop"
    position="right"
    [class.list-item--not-active]="activeRouteId !== item.id"
    (click)="navigateMenuItem(item.url)"
  >
    <img
      [src]="'assets/menu/' + item.icon + '.svg'"
      draggable="false"
      class="svg-default"
    />
    <div *ngIf="!collapsed || !isDesktop" class="list-item__title">
      {{ item.title }}
    </div>
  </div>
</div>
